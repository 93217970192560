
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class PromoBadge extends Vue {
  @Prop({ type: String, required: true })
  readonly promo!: 'black-april' | 'summer'

  get pictures () {
    switch (this.promo) {
      case 'black-april':
        return [
          require('~/assets/images/promos/black-april/black-april-badge.png'),
          require('~/assets/images/promos/black-april/black-april-badge@2x.png'),
        ]

      case 'summer':
        return [
          require('~/assets/images/badges/summer-badge.webp'),
          require('~/assets/images/badges/summer-badge@2x.webp'),
        ]
    }
  }

  get srcsetStr () {
    return `${this.pictures[0]} 1x, ${this.pictures[1]} 2x`
  }
}
