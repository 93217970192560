var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _vm._m(0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"partners section",attrs:{"data-e2e":"partnersSection"}},[_c('div',{staticClass:"container partners__container"},[_c('h3',{staticClass:"section__title"},[_vm._v("\n      "+_vm._s(_vm.$t('partners.title'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"partners__list"},_vm._l((_vm.partners),function(partner){return _c('NuxtLink',{key:partner.slug,class:[
          'partners__item',
          'partners__item--' + partner.slug
        ],attrs:{"to":_vm.localePath({
          name: 'provider-slug',
          params: {
            slug: partner.slug,
          },
        }),"data-e2e":"partnerLink"}},[_c('img',{attrs:{"loading":"lazy","src":partner.images.src,"srcset":partner.images.srcset,"alt":partner.name}})])}),1)])])
}]

export { render, staticRenderFns }