var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container main-banner"},[_c('div',{staticClass:"main-banner__content"},[(_vm.isShowPromoBadge)?_c('PromoBadge',{staticClass:"main-banner__badge",attrs:{"promo":_vm.promoBadgeType}}):_vm._e(),_vm._v(" "),_c('h1',{staticClass:"main-banner__title",class:{'main-banner__title--white': _vm.featureBlackApril},attrs:{"data-e2e":"jumbotronTitle"},domProps:{"innerHTML":_vm._s(_vm.$t(_vm.featureBlackApril ? 'home.blackFridayJumbotron.title' : 'home.newJumbotron.title'))}}),_vm._v(" "),_c('SavingsCounter',{staticClass:"main-banner__counter",attrs:{"orders":_vm.totalOrders,"savings":_vm.totalSavings,"one-counter-mode":_vm.featureBlackApril || _vm.featureMayTheme || _vm.featureSummerTheme,"dark-mode":_vm.featureBlackApril}}),_vm._v(" "),_c('AlaoButton',{staticClass:"main-banner__cta text--center",attrs:{"href":_vm.localePath('quiz'),"size":"lg","data-e2e":"jumbotronFindPlanBtn"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onCTAClick()}}},[_vm._v("\n      "+_vm._s(_vm.featureBlackApril ? _vm.$t('home.findYourPlan') : _vm.$t('home.newJumbotron.startSaving'))+"\n    ")])],1),_vm._v(" "),_c('div',{staticClass:"main-banner__img",class:{
      'main-banner__img--feature-black-april': _vm.featureBlackApril,
      'main-banner__img--feature-new-year': _vm.featureNewYear,
      'main-banner__img--feature-valentine-promo': _vm.featureValentinePromo,
      'main-banner__img--feature-spring-theme': _vm.featureSpringTheme,
      'main-banner__img--feature-easter-theme': _vm.featureEasterTheme,
      'main-banner__img--feature-may-theme': _vm.featureMayTheme,
      'main-banner__img--feature-summer-theme': _vm.featureSummerTheme,
    }})])
}
var staticRenderFns = []

export { render, staticRenderFns }