
import { Component, Prop, Vue } from 'nuxt-property-decorator'

type Partner = 'swisscom' | 'sunrise' | 'salt' | 'wingo' | 'yallo' | 'talktalk'

const partnerImagesMap: Record<Partner, any[]> = {
  swisscom: [require('~/assets/images/provider-logos/v5/swisscom-world.png'), require('~/assets/images/provider-logos/v5/swisscom-world@2x.png')],
  sunrise: [require('~/assets/images/provider-logos/v5/sunrise.svg')],
  salt: [require('~/assets/images/provider-logos/v5/salt.svg')],
  wingo: [require('~/assets/images/provider-logos/v5/wingo.svg')],
  yallo: [require('~/assets/images/provider-logos/v5/yallo.svg')],
  talktalk: [require('~/assets/images/provider-logos/v5/talktalk.svg')],
}

@Component
export default class Partners extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly withShadow!: boolean

  partnerLogos (partner: Partner) {
    const images = partnerImagesMap[partner]

    return {
      srcset: images.map((image, index) => `${image} ${index + 1}x`).join(', '),
      src: images[0],
    }
  }

  get partners () {
    return [
      {
        name: 'Swisscom',
        slug: 'swisscom',
        images: this.partnerLogos('swisscom'),
      },
      {
        name: 'Sunrise',
        slug: 'sunrise',
        images: this.partnerLogos('sunrise'),
      },
      {
        name: 'Salt',
        slug: 'salt',
        images: this.partnerLogos('salt'),
      },
      {
        name: 'Wingo',
        slug: 'wingo',
        images: this.partnerLogos('wingo'),
      },
      {
        name: 'Yallo',
        slug: 'yallo',
        images: this.partnerLogos('yallo'),
      },
      {
        name: 'TalkTalk',
        slug: 'talktalk',
        images: this.partnerLogos('talktalk'),
      },
    ]
  }
}
