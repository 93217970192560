
import { Component, namespace, Vue } from 'nuxt-property-decorator'
import SavingsCounter from '~/components/SavingsCounter/SavingsCounter.vue'
import PromoBadge from '~/components/badges/PromoBadge.vue'
import { CommonState, namespace as commonStore } from '~/store/modules/Common'
import { FeatureFlags } from '~/core/config/feature-flags'

const CommonStore = namespace(commonStore)

@Component({
  components: {
    SavingsCounter,
    PromoBadge,
  },
})
export default class NewMainBanner extends Vue {
  featureBlackApril = this.$featureFlags.isEnabled(FeatureFlags.BLACK_APRIL_PROMO)
  featureSummerDealsPromo = this.$featureFlags.isEnabled(FeatureFlags.SUMMER_DEALS_PROMO)
  featureValentinePromo = this.$featureFlags.isEnabled(FeatureFlags.VALENTINE_PROMO)
  featureSpringTheme = this.$featureFlags.isEnabled(FeatureFlags.SPRING_THEME)
  featureEasterTheme = this.$featureFlags.isEnabled(FeatureFlags.EASTER_THEME)
  featureMayTheme = this.$featureFlags.isEnabled(FeatureFlags.MAY_THEME)
  featureSummerTheme = this.$featureFlags.isEnabled(FeatureFlags.SUMMER_THEME)

  featureNewYear = this.$featureFlags.isEnabled(FeatureFlags.NEW_YEAR)

  @CommonStore.State('totalOrders')
  readonly totalOrders!: CommonState['totalOrders']

  @CommonStore.State('totalSavings')
  readonly totalSavings!: CommonState['totalSavings']

  get isShowPromoBadge () {
    return this.featureBlackApril || this.featureSummerDealsPromo
  }

  get promoBadgeType () {
    if (this.featureBlackApril) {
      return 'black-april'
    }

    if (this.featureSummerDealsPromo) {
      return 'summer'
    }

    return ''
  }

  onCTAClick () {
    const btnText = this.$t('home.newJumbotron.startSaving') as string

    this.$analytics.gtm.sendQuizCTAClickEvent(this.$i18n.locale, btnText)

    this.$router.push(this.localePath('quiz'))
  }
}
